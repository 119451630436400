<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20 space-y-7 w-full">
        <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Tạo chủ đề thảo luận mới</h1>
        <div class="breadcrumb-area py-0 flex items-center justify-content-between">
          <div class="breadcrumb">
            <ul class="m-0">
              <li>
                <router-link title="Diễn đàn" :to="{name: 'ForumIndex'}">
                  Diễn đàn
                </router-link>
              </li>
              <li v-if="forum && forum.parent">
                <router-link :title="forum.parent.title"
                             :to="{name: 'ForumDetail', params: {id: forum.parent.id, slug: forum.parent.slug}}">
                  {{ forum.parent.title }}
                </router-link>
              </li>
              <li v-if="forum && forum.slug">
                <router-link :title="forum.title" :to="{name: 'ForumDetail', params: {id: forum.id, slug: forum.slug}}">
                  {{ forum.title }}
                </router-link>
              </li>
              <li class="active">
                Tạo chủ đề mới
              </li>
            </ul>
          </div>
        </div>
        <div v-if="message">
          <alert :message="message" :status="status"/>
        </div>
        <form v-on:submit.prevent="createDiscussion" class="grid grid-cols-1 gap-3">
          <div>
            <label for="title" class="font-semibold">Tiêu đề (*)</label>
            <input v-model="discussion.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                   required>
          </div>
          <div>
            <label for="title" class="font-semibold">Chuyên mục (*)</label>
            <select v-model="discussion.forum_id">
              <optgroup :label="forum.title" v-for="forum in forums" :key="'forum' + forum.id">
                <template v-if="forum.children && forum.children.data">
                  <option :value="child.id" v-for="child in forum.children.data" :key="'forum' + child.id">
                    {{ child.title }}
                  </option>
                </template>
              </optgroup>
            </select>
          </div>
          <div>
            <label for="content" class="font-semibold">Nội dung (*)</label>
            <editor :full="true" id="content" v-model:content="discussion.content"/>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Khóa chủ đề? <i class="far fa-question-circle"
                                                                     :uk-tooltip="'Chủ đề bị khóa sẽ không thể bình luận'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isClosed">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Ghim bình luận? <i class="far fa-question-circle"
                                                                        :uk-tooltip="'Comment mới nhất của chủ đề sẽ luôn hiện lên đầu'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isStickyComment">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Ghim chủ đề? <i class="far fa-question-circle"
                                                                     :uk-tooltip="'Chủ đề được ghim sẽ hiện nổi bật trên trang chủ'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isSticky">
              <span class="switch-button"></span>
            </label>
          </div>
          <div v-if="isAdmin">
            <label for="title" class="font-semibold">Chủ đề riêng tư? <i class="far fa-question-circle"
                                                                         :uk-tooltip="'Chủ đề chỉ những người được cấp quyền mới xem được'"></i></label>
            <label class="switch flex items-center mb-4">
              <input type="checkbox" v-model="isPrivate">
              <span class="switch-button"></span>
            </label>
          </div>
          <div class="border-t flex justify-center border-gray-100 p-6">
            <button :disabled="disabled" type="submit" class="button lg:w-1/3 hover:bg-blue-800">Tạo chủ đề</button>
          </div>
        </form>
      </div>
    </div>

    <alert :message="message"/>
  </div>
</template>

<script>
import Editor from "../Editor";
import ApiService from "../../core/services/api.service";
import Alert from "../Alert";
import {canUploadSong, isAdmin, showAlert} from "../../core/services/utils.service";

export default {
  name: "DiscussionAdd",
  components: {Alert, Editor},
  data() {
    return {
      forum_id: null,
      forum: {
        id: null,
        title: "",
        slug: "",
      },
      discussion: {
        title: "",
        content: "",
        is_closed: 0,
        sticky_comment: 0,
        is_sticky: 0,
        is_private: 0,
        forum_id: 5
      },
      disabled: false,
      status: "",
      message: "",
      forums: [],
      isClosed: false,
      isStickyComment: false,
      isSticky: false,
      isPrivate: false
    }
  },
  methods: {
    createDiscussion() {
      this.disabled = true;
      this.message = "";

      let query = `mutation($title: String!, $content: String, $forum_id: ID!, $is_closed: Int, $sticky_comment: Int, $is_sticky: Int, $is_private: Int) {
        createDiscussion(input: {
          title: $title,
          forum_id: $forum_id,
          is_closed: $is_closed,
          sticky_comment: $sticky_comment,
          is_sticky: $is_sticky,
          is_private: $is_private,
          content: $content
        }) {
          id
          slug
          status
        }
      }`;

      ApiService.graphql(query, this.discussion)
          .then(({data}) => {
            if (data.data && data.data.createDiscussion) {
              if (data.data.createDiscussion.status === 2) {
                this.message = "Bài viết của bạn đã tạo thành công, đang chờ BBT kiểm duyệt.";
                this.discussion.title = "";
                this.discussion.content = "";
                showAlert();
              } else {
                this.$router.push({
                  name: "DiscussionDetail",
                  params: {id: data.data.createDiscussion.id, slug: data.data.createDiscussion.slug}
                });
              }
            } else {
              this.status = "error";
              this.message = data.errors[0].message;
              window.scrollTo(0, 0);
              this.disabled = false;
              showAlert();
            }
          })
          .catch((response) => {
            this.status = "error";
            this.message = response.message;
            window.scrollTo(0, 0);
            this.disabled = false;
            showAlert();
          });
    },
    loadForums() {
      let query = `query {
        forums(first: 100, where: {AND: [{column: "parent_id", value: null}]}, orderBy: [{column: "position", order: ASC}]) {
          data {
            id
            title
            children(first: 100) {
              data {
                id
                title
              }
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.forums && data.data.forums.data) {
              this.forums = data.data.forums.data;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadForum() {
      let query = `query($id: ID!) {
        forum(id: $id) {
          id
          title
          slug
          parent {
            id
            title
            slug
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.forum_id})
          .then(({data}) => {
            if (data.data && data.data.forum) {
              this.forum = data.data.forum;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.forum_id = this.$route.query.forum_id;
    if (this.canUploadSong && this.forum_id == 28) {
      this.$router.push({name: 'UploadAdd', params: {prefix: 'tan-nhac'}});
    } else {
      this.loadForums();
      if (this.forum_id) {
        this.discussion.forum_id = this.forum_id;
        this.loadForum();
      }
    }
  },
  watch: {
    isClosed: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_closed = 1;
        } else {
          this.discussion.is_closed = 0;
        }
      }
    },
    isStickyComment: {
      handler(newVal) {
        if (newVal) {
          this.discussion.sticky_comment = 1;
        } else {
          this.discussion.sticky_comment = 0;
        }
      }
    },
    isSticky: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_sticky = 1;
        } else {
          this.discussion.is_sticky = 0;
        }
      }
    },
    isPrivate: {
      handler(newVal) {
        if (newVal) {
          this.discussion.is_private = 1;
        } else {
          this.discussion.is_private = 0;
        }
      }
    }
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    canUploadSong() {
      return canUploadSong();
    }
  }
}
</script>
